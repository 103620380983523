.category-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f0f0f0;
}

.nav-item {
  padding: 10px 15px;
  margin: 0 5px;
  text-decoration: none;
  color: #333;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.nav-item:hover {
  background-color: #e0e0e0;
}

.nav-item.active {
  background-color: #4CAF50;
  color: white;
}

.home-button {
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
}

.home-button:hover {
  background-color: #363636;
}